// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-home {
  font-family: "Montserrat-Thin";
  font-style: normal;
  /* font-weight: 200; */
  font-size: 48px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
}

.text2-home {
  font-family: "Montserrat-Light";
  font-style: normal;
  /* font-weight: 400; */
  font-size: 20px;
  line-height: 125%;
  font-variant: small-caps;
  display: flex;
  justify-content: center;
}

.text3-home {
  position: absolute;
  top: 0;
  right: 0;
  font-family: "Montserrat-Light";
  font-style: normal;
  /* font-weight: 400; */
  font-size: 12px;
  line-height: 125%;
  font-variant: small-caps;
  display: flex;
  justify-content: right;
  color: #171e27;
  padding: 4px;
}

.margin-button {
  margin-top: 12px;
}

ion-item {
  --background: transparent;
  --color: #fff;
}

ion-button {
  --background: #062f77;
}

ion-grid {
  height: 100%;
}

ion-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
}

.marginIcon {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/login/login.page.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;AACF;;AACA;EACE,+BAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;EACA,aAAA;EACA,uBAAA;AAEF;;AAAA;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,+BAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,iBAAA;EACA,wBAAA;EACA,aAAA;EACA,sBAAA;EACA,cAAA;EACA,YAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AAFA;EACE,yBAAA;EACA,aAAA;AAKF;;AAHA;EACE,qBAAA;AAMF;;AAJA;EACE,YAAA;AAOF;;AALA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAQF;;AANA;EACE,gBAAA;AASF","sourcesContent":[".text-home{\n  font-family: 'Montserrat-Thin';\n  font-style: normal;\n  /* font-weight: 200; */\n  font-size: 48px;\n  color: #FFFFFF;\n  display: flex;\n  justify-content: center;\n}\n.text2-home{\n  font-family: 'Montserrat-Light';\n  font-style: normal;\n  /* font-weight: 400; */\n  font-size: 20px;\n  line-height: 125%;\n  font-variant: small-caps;\n  display: flex;\n  justify-content: center;\n}\n.text3-home{\n  position:absolute;\n  top:0;\n  right:0;\n  font-family: 'Montserrat-Light';\n  font-style: normal;\n  /* font-weight: 400; */\n  font-size: 12px;\n  line-height: 125%;\n  font-variant: small-caps;\n  display: flex;\n  justify-content: right;\n  color: #171e27;\n  padding: 4px;\n}\n.margin-button{\n  margin-top: 12px;\n}\nion-item{\n  --background: transparent;\n  --color: #fff;\n}\nion-button{\n  --background: #062f77;\n}\nion-grid{\n  height: 100%;\n}\nion-row{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 95%;\n}\n.marginIcon {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
