/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SalesforceAuthService } from './salesforceAuthService';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { BehaviorSubject, Observable, from, Subject } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { parseString } from 'xml2js';
const TOKEN_KEY = 'session-id';
const TOKEN_EXPIRATION = 'session-expiration';
const UNDERLYINGS_KEY = 'und-id';
const USER_KEY = 'user-id';
const USER_MRG = 'user-mrg';
const USER_ISS = 'user-iss';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  constructor(private http: HttpClient,private authService: SalesforceAuthService) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Preferences.get({ key: TOKEN_KEY });
    const tokenExp = await Preferences.get({ key: TOKEN_EXPIRATION });
    if (token && token.value && tokenExp && tokenExp.value) {
      const dateExp = new Date(Number(tokenExp.value));
      if (new Date() <= dateExp) {
        this.token = token.value;
        this.isAuthenticated.next(true);
      } else {
        this.isAuthenticated.next(false);
      }
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: { email; password }): Observable<any> {
    this.clearPreferences();
    if (credentials.email.includes('@aisfg.com')) {
      return this.internalLogin(credentials);
    } else {
      throw new Error('Only AIS employees are allowed to enter.');
      //return this.externalLogin(credentials);
    }
  }

  internalLogin(credentials: { email: string; password: string }): Observable<any> {
    return this.authService.internalLogin(credentials).pipe(
      map(result => {
        console.log('Login exitoso:', result);  // Registro en caso de éxito
  
        // Guarda el token y otros datos
        Preferences.set({ key: TOKEN_EXPIRATION, value: (new Date().getTime() + 7200000) + '' });
        this.token = result['access_token']; 
        Preferences.set({ key: TOKEN_KEY, value: result['access_token'] });
        Preferences.set({ key: USER_KEY, value: result['user_id'] });
        this.isAuthenticated.next(true);
  
        return { internalUser: true };
      }),
      catchError(error => {
        console.error('Error durante el login:', error);  // Registro del error
  
        // Puedes mostrar algún mensaje de error al usuario o manejar el error de otra forma
        return throwError(error);  // Propaga el error para que sea manejado en la llamada
      })
    );
  }

  externalLogin(credentials: { email; password }): Observable<any> {
    return this.authService.communityLogin(credentials).pipe(
      map((response) => {
        const sessionId = response['session_id'];
        const sessionExp = response['session_exp'];
        const timeObject = new Date();
        const milliseconds = Number(sessionExp) * 1000;
        Preferences.set({ key: TOKEN_EXPIRATION, value: (timeObject.getTime() + milliseconds) + '' });
        this.token = sessionId;
        this.isAuthenticated.next(true);
        return { internalUser: false };
      })
    );
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    Preferences.remove({ key: UNDERLYINGS_KEY });//just remove if explicity loged out
    return this.clearPreferences();
  }

  private clearPreferences(): Promise<void> {
    Preferences.remove({ key: TOKEN_EXPIRATION });
    //Preferences.remove({ key: UNDERLYINGS_KEY });
    Preferences.remove({ key: USER_MRG });
    Preferences.remove({ key: USER_ISS });
    Preferences.remove({ key: USER_KEY });
    return Preferences.remove({ key: TOKEN_KEY });
  }
  private getXMLRequest(email, password) {
    //Jose 00D6C0000009yZX
    return `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:urn="urn:enterprise.soap.sforce.com">
        <soapenv:Header>
          <urn:LoginScopeHeader>
            <urn:organizationId>00D2i0000008geXEAQ</urn:organizationId>
          </urn:LoginScopeHeader>
        </soapenv:Header>
        <soapenv:Body>
          <urn:login>
            <urn:username>`+ email + `</urn:username>
            <urn:password>`+ password + `</urn:password>
          </urn:login>
        </soapenv:Body>
      </soapenv:Envelope>
    `;
  }

}
