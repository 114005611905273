import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { DataService } from '../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Preferences } from '@capacitor/preferences';
const UNDERLYINGS_KEY = 'und-id';

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.page.html',
  styleUrls: ['./search-modal.page.scss'],
})
export class SearchModalPage implements OnInit {
  searchModal;
  searchResults = [];
  showSuggestions = true;
  loading = false;
  searchCount = 0;
  selectedIds;
  lastSearchs: any[] = [];

  constructor(modalCtrl: ModalController, private dataService: DataService, private alertController: AlertController,
    private translate: TranslateService) {
    this.searchModal = modalCtrl;
  }

  ngOnInit() {
    Preferences.get({ key: UNDERLYINGS_KEY }).then((unds) => {
      if (unds?.value) {
        this.lastSearchs = JSON.parse(unds.value.toString());
      }
    });
    //Preferences.set({ key: LANG_KEY, value: result.data.lang });
  }

  dismissModal() {
    this.searchModal.dismiss();
  }
  handleSearch(e) {
    const searchTerm = e.target.value;
    const currentCount = ++this.searchCount;
    if (searchTerm && searchTerm.length >= 3) {
      this.showSuggestions = false;
      this.loading = true;
      this.searchUnderlyings(searchTerm, currentCount);
    } else {
      this.searchResults = [];
      this.showSuggestions = true;
    }
  }

  selectUnderlying(selectedItem) {
    if (!this.lastSearchs.map(und => und.Id).includes(selectedItem.Id)) {
      this.lastSearchs.push(selectedItem);
      Preferences.set({ key: UNDERLYINGS_KEY, value: JSON.stringify(this.lastSearchs) });
    }

    this.searchModal.dismiss(selectedItem);
  }

  async searchUnderlyings(searchTerm, petitionCount) {
    this.dataService.searchUnderlyings(searchTerm, this.selectedIds).subscribe(
      async (res) => {
        this.loading = false;
        if (res.done && petitionCount === this.searchCount) {
          this.searchResults = res.records;
        }
      },
      async (res) => {
        this.loading = false;
        console.log('error', res);
        const alert = await this.alertController.create({
          header: this.translate.instant('FORM.ERROR'),
          message: res.error,
          buttons: [this.translate.instant('FORM.CLOSE')]
        });

        await alert.present();
      }
    );
  }

}
