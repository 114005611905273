/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { DataService } from '../services/data.service';
import pkg from '../../../package.json';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  public version: string = pkg.version;
  showPassword = false;

  credentials: FormGroup;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private dataService: DataService,
    private alertController: AlertController,
    private translate: TranslateService,
    private router: Router,
    private loadingController: LoadingController,
    private toastCtrl: ToastController) {

  }
  ngOnInit(): void {
    this.credentials = this.fb.group({
      email: [, [Validators.required, Validators.email]],
      password: [, [Validators.required, Validators.minLength(6)]]
      /* email: ['test@aisfg.com', [Validators.required, Validators.email]],
      password: ['crm4ever', [Validators.required, Validators.minLength(6)]] */
      /* email: ['fabio.martinez@aisfg.com.personaljf', [Validators.required, Validators.email]],
      password: ['@crm125Ibiza', [Validators.required, Validators.minLength(6)]] */
    });
    const state = this.router.getCurrentNavigation().extras.state;
    if (state && state.msg) {
      this.presentToast(state.msg);
    }
  }

  async login() {
    const loading = await this.loadingController.create();
    await loading.present();
    try {
      this.authService.login(this.credentials.value).subscribe({
        next: async (res) => {
          if (res && res.internalUser) {
            await loading.dismiss();
            this.router.navigateByUrl('/tabs', { replaceUrl: true });
          } else {
            this.dataService.getMarginsANDIssuerVisibility().subscribe({
              next: async (res2) => {
                console.log(res2);
                await loading.dismiss();
                this.router.navigateByUrl('/tabs', { replaceUrl: true });
              },
              error: async (res2) => {
                console.log('error', res2);
                await loading.dismiss();
                const alert = await this.alertController.create({
                  header: this.translate.instant('FORM.VISIBILITY_FAILED'),
                  message: res2.error,
                  buttons: [this.translate.instant('FORM.CLOSE')]
                });

                await alert.present();
              }
            });
          }
        },
        error: async (res) => {
          console.log('error', res);
          await loading.dismiss();

          if (res?.status !== 401) {
            const alert = await this.alertController.create({
              header: this.translate.instant('FORM.LOGIN_FAILED'),
              message: res.error,
              buttons: [this.translate.instant('FORM.CLOSE')]
            });

            await alert.present();
          }
        }
      });
    } catch (e) {
      console.log('error', e);
      await loading.dismiss();
      const alert = await this.alertController.create({
        header: this.translate.instant('FORM.LOGIN_FAILED'),
        message: e,
        buttons: [this.translate.instant('FORM.CLOSE')]
      });

      await alert.present();
    }
  }

  // Easy access for form fields
  get email() {
    return this.credentials.get('email');
  }

  get password() {
    return this.credentials.get('password');
  }

  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: [
        {
          text: this.translate.instant('SEARCH.CLOSE'),
          role: 'cancel'
        },
      ],
    });

    await toast.present();

    const { role } = await toast.onDidDismiss();
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
}
