// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-row {
  margin-top: 20px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.flex-row ion-avatar {
  margin-right: -8px;
}

.empty-text {
  margin-top: 2px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/search-modal/search-modal.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AACA;EACI,kBAAA;AAEJ;;AAAA;EACE,eAAA;EACA,kBAAA;AAGF","sourcesContent":[".flex-row{\n    margin-top: 20px;\n    display: flex;\n    text-align: center;\n    align-items: center;\n    flex-direction: row;\n    justify-content: center;\n}\n.flex-row ion-avatar{\n    margin-right: -8px;\n}\n.empty-text{\n  margin-top: 2px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
