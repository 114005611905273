// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-title {
  font-family: "Montserrat-Thin";
  font-style: normal;
  /* font-weight: 300; */
  font-size: 20px;
  color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/app/tabs/tabs.page.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".text-title{\n  font-family: 'Montserrat-Thin';\n  font-style: normal;\n  /* font-weight: 300; */\n  font-size: 20px;\n  color: #FFFFFF;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
