import { Component } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Preferences } from '@capacitor/preferences';
import { register } from 'swiper/element/bundle';
import { SwUpdate } from '@angular/service-worker';
const LANG_KEY = 'lang-id';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public installPromt = null;
  constructor(private actionSheetCtrl: ActionSheetController, private translate: TranslateService, private swUpdate: SwUpdate) {
    this.initializeApp();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    Preferences.get({ key: LANG_KEY }).then((lang) => {
      if (lang && lang.value) {
        translate.use(lang.value.toString());
      } else {
        translate.use('en');
      }
    });
    // the lang to use, if the lang isn't available, it will use the current loader to get them

  }

  initializeApp(): void {
    if (this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        if (confirm('A new version is available. Load it?')) {
          window.location.reload();
        }
      });
    }
  }
  /* getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      this.installPromt = e;
    });
  }
  askUserToInstallApp() {
    this.installPromt.promt();
  } */
}
