import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-price-selector-modal',
  templateUrl: './price-selector-modal.component.html',
  styleUrls: ['./price-selector-modal.component.scss'],
})
export class PriceSelectorModalComponent {

  @Input() results: any;
  @Input() issuers: any;
  selectedPricesArray: { bank: string, normalizedName: string, result: string, selected: boolean }[] = [];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.initializePrices();
  }

  initializePrices() {
    console.log('this.issuers[bank] ', this.issuers);
    Object.keys(this.results).forEach(bank => {
      let result = this.results[bank]?.result;
      if (result && result.trim() !== '') {
        result = result.replace('%', '');
        const normalizedName = this.issuers[bank] || bank;
        this.selectedPricesArray.push({
          bank,
          normalizedName,
          result,
          selected: false
        });
      }
    });
    console.log('Selected prices array:', this.selectedPricesArray);
  }

  onShare() {
    const selectedData = this.selectedPricesArray
      .filter(item => item.selected)
      .reduce((obj, item) => {
        obj[item.bank] = item.result;
        return obj;
      }, {});

    this.modalCtrl.dismiss(selectedData);
  }

  onClose() {
    this.modalCtrl.dismiss();
  }
}
