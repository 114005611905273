/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@capacitor/clipboard';
import { constants } from './../tab2/tab2.page';
const RESULT = { 'NULL': 0, 'NOT_AVAILABLE': 1, 'ERROR': 2, 'VALID': 3 };
const SOLVER_FORS = ['Offer Price', 'Barrier Level', 'Barrier Level equal to Coupon Trigger', 'Coupon Trigger', 'Coupon'];

@Component({
  selector: 'app-historic-modal',
  templateUrl: './historic-modal.page.html',
  styleUrls: ['./historic-modal.page.scss'],
})
export class HistoricModalPage implements OnInit {
  detailsModal;
  that = this;
  readonly issuers = constants.issuers;
  selectedPricer: {CouponType__c; PricerUnderlyings__r; Name; ProductSubtype__c; CapLevel__c; CapitalProtection__c; Maturity__c; CurrencyIsoCode; BarrierLevel__c; BarrierType__c; CallStrike__c; Cap__c; Participation__c; CouponTrigger__c; Couponpa__c; Solvefor__c; Pricer_price_by_banks__r: {records: [any]}};
  constructor(modalCtrl: ModalController, private router: Router, private translate: TranslateService, private alertCtrl: AlertController, private toastCtrl: ToastController) {
    this.detailsModal = modalCtrl;
  }

  ngOnInit() {
  }

  dismissModal() {
    this.detailsModal.dismiss();
  }
  reprice(selectedItem) {
    this.router.navigateByUrl('/tabs/tab2', {
      state: { pricing: selectedItem }
    });
    this.dismissModal();
  }
  async showIssuerAlert(priceResult) {
    const resultMsg = priceResult?.Error__c ? this.translate.instant('HISTORIC.ERROR') : this.translate.instant('HISTORIC.QUOTE_ID');
    const result = priceResult?.Error__c ? priceResult?.ErrorMessage__c :  priceResult?.IssuerId__c;
    const alert = await this.alertCtrl.create({
      header: priceResult?.Issuer__r?.Name,
      message: resultMsg + ': ' + result,
      buttons: [
        {
          text: this.translate.instant('HISTORIC.COPY'),
          role: 'cancel',
          handler: () => {
            // eslint-disable-next-line id-blacklist
            Clipboard.write({ string: result});
            this.presentToast(this.translate.instant('HISTORIC.COPIED'));
            //this.selectedUnderlyings.splice(index, 1);
          }
        },
        {
          text: this.translate.instant('HISTORIC.CLOSE'),
          handler: () => {
            //this.items.splice(i,1);
          }
        }
      ]
    });
    alert.present();
  }
  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: [
        {
          text: this.translate.instant('SEARCH.CLOSE'),
          role: 'cancel'
        },
      ],
    });

    await toast.present();

    const { role } = await toast.onDidDismiss();
  }

  ordered = (a, b) => {
    const codeA = a?.value?.Result__c === null || a?.value?.Result__c === 'Error' ? 0 : 1;
    const codeB = b?.value?.Result__c === null || b?.value?.Result__c === 'Error' ? 0 : 1;
    if (codeA > codeB) {
      return -1;
    } else if (codeB > codeA) {
      return 1;
    } else {
      if (this?.selectedPricer?.Solvefor__c === SOLVER_FORS[4]) {
        if (a?.value?.Result__c > b?.value?.Result__c) {
          return -1;
        } else if (a?.value?.Result__c < b?.value?.Result__c) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (a?.value?.Result__c > b?.value?.Result__c) {
          return 1;
        } else if (a?.value?.Result__c < b?.value?.Result__c) {
          return -1;
        } else {
          return 0;
        }
      }
      return 0;
    }
  };
}
