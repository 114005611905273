/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { Preferences } from '@capacitor/preferences';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from './../../environments/environment';

const USER_MRG = 'user-mrg';
const USER_ISS = 'user-iss';

/* export interface ApiResult {
  page: number;
  results: any[];
  total_pages: number;
  total_results: number;
} */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private authService: AuthService, private http: HttpClient, private router: Router) {
  }

  getAccounts(): Observable<any> {
    const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name+FROM+Account';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers });
  }

  getHistoricalSummary(offset: number, userId: string): Observable<any> {
    // eslint-disable-next-line max-len
    const whereCreatedBy = userId ? '+AND+CreatedById=\'' + userId + '\'' : '';
    const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name,Favorite__c,Batched__c,BestResult__c,BestIssuer__c,BatchHours__c,AutocallStepDown__c,CreatedDate,CreatedBy.Name,CreatedBy.Contact.Account.ID_Number__c,SolveFor__c,NotionalAmmount__c,AutocallTrigger__c,BarrierLevel__c,Couponpa__c,CouponTrigger__c,FirstCallInObservation__c,Maturity__c,IssuePrice__c,PutStrike__c,OfferPrice__c,BarrierType__c,CallStrike__c,Participation__c,CouponType__c,CurrencyIsoCode,Delivery__c,Cap__c,CapLevel__c,ObservationFrequency__c,ProductRecordType__c,ProductSubtype__c,OneStar__c,OneStarLevel__c,CapitalProtection__c,(SELECT+Underlying__c,Underlying__r.Logo__c,Underlying__r.RICCode__c,Underlying__r.Name,Underlying__r.Name__c,Underlying__r.Website__c+FROM+PricerUnderlyings__r),(SELECT+Issuer__r.PricerId__c,Issuer__r.Name,Issuer__r.ShortName__c,Result__c,IssuerId__c,Error__c,ErrorMessage__c+FROM+Pricer_price_by_banks__r+ORDER+BY+Error__c,Result__c+asc)+FROM+Pricer__c+WHERE+RecordType.Name=\'Pricer\'' + whereCreatedBy + '+ORDER+BY+CreatedDate+desc+LIMIT+15+OFFSET+' + offset;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers });
  }
  getResultsFromPricer(pricerId: string): Observable<any> {
    // eslint-disable-next-line max-len
    const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name,(SELECT+Issuer__r.PricerId__c,Issuer__r.Name,Result__c,IssuerId__c,Error__c,ErrorMessage__c+FROM+Pricer_price_by_banks__r+ORDER+BY+Error__c,Result__c+asc)+FROM+Pricer__c+WHERE+RecordType.Name=\'Pricer\'+AND+Name=\'' + pricerId + '\'';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers });
  }

  getIdeas(): Observable<any> {
    // eslint-disable-next-line max-len
    //const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name+FROM+Idea__c';
    //const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name,Priority__c,Pricer__r.Id,Pricer__r.Name,Pricer__r.Favorite__c,Pricer__r.Batched__c,Pricer__r.BatchHours__c,Pricer__r.AutocallStepDown__c,Pricer__r.CreatedDate,Pricer__r.CreatedBy.Name,Pricer__r.CreatedBy.Contact.Account.ID_Number__c,Pricer__r.SolveFor__c,Pricer__r.NotionalAmmount__c,Pricer__r.AutocallTrigger__c,Pricer__r.BarrierLevel__c,Pricer__r.Couponpa__c,Pricer__r.CouponTrigger__c,Pricer__r.FirstCallInObservation__c,Pricer__r.Maturity__c,Pricer__r.IssuePrice__c,Pricer__r.PutStrike__c,Pricer__r.OfferPrice__c,Pricer__r.BarrierType__c,Pricer__r.CallStrike__c,Pricer__r.Participation__c,Pricer__r.CouponType__c,Pricer__r.CurrencyIsoCode,Pricer__r.Delivery__c,Pricer__r.Cap__c,Pricer__r.CapLevel__c,Pricer__r.ObservationFrequency__c,Pricer__r.ProductRecordType__c,Pricer__r.ProductSubtype__c,Pricer__r.OneStar__c,Pricer__r.OneStarLevel__c,Pricer__r.CapitalProtection__c,(SELECT+Underlying__c,Underlying__r.Logo__c,Underlying__r.RICCode__c,Underlying__r.Name,Underlying__r.Name__c,Underlying__r.Website__c+FROM+Pricer__r.PricerUnderlyings__r)FROM+Idea__c+WHERE+Active__c=+true+ORDER+BY+Priority__c+desc+LIMIT+10';
    //const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name,Favorite__c,Batched__c,BatchHours__c,AutocallStepDown__c,CreatedDate,CreatedBy.Name,CreatedBy.Contact.Account.ID_Number__c,SolveFor__c,NotionalAmmount__c,AutocallTrigger__c,BarrierLevel__c,Couponpa__c,CouponTrigger__c,FirstCallInObservation__c,Maturity__c,IssuePrice__c,PutStrike__c,OfferPrice__c,BarrierType__c,CallStrike__c,Participation__c,CouponType__c,CurrencyIsoCode,Delivery__c,Cap__c,CapLevel__c,ObservationFrequency__c,ProductRecordType__c,ProductSubtype__c,OneStar__c,OneStarLevel__c,CapitalProtection__c,(SELECT+Id,Name,Priority__c,Type__c+FROM+Ideas__r+WHERE+Active__c=+true+ORDER+BY+Priority__c+desc+LIMIT+1),(SELECT+Underlying__c,Underlying__r.Logo__c,Underlying__r.RICCode__c,Underlying__r.Name,Underlying__r.Name__c,Underlying__r.Website__c+FROM+PricerUnderlyings__r)FROM+Pricer__c+WHERE+Id+in+(SELECT+Pricer__c+FROM+Idea__c+WHERE+Active__c=+true)';
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getIdeas';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers });
  }
  getMarginsANDIssuerVisibility(): Observable<any> {
    // eslint-disable-next-line max-len
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getMarginsANDIssuerVisibility';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers }).pipe(map(
      result => {
        Preferences.set({ key: USER_MRG, value: JSON.stringify(result['margins']) });
        Preferences.set({ key: USER_ISS, value: JSON.stringify(result['IssuerVisibility']) });
        return { internalUser: true };
      }));
  }
  searchUnderlyings(search: string, ignoreIds?: string): Observable<any> {
    // eslint-disable-next-line max-len
    const searchTerm = '%25' + search + '%25';
    const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name,Name__c,Logo__c,RICCode__c,X3M_imp_put_vol__c,Website__c+FROM+Underlying__c+WHERE+(Name+LIKE+\'' + searchTerm + '\'+OR+Name__c+LIKE+\'' + searchTerm + '\')+AND+AvailableInPricer__c+=+true+' + (ignoreIds ? 'AND+Id+NOT+IN+(' + ignoreIds + ')' : '') + '+LIMIT+10';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers });
  }
  sendPricing(json: string): Observable<any> {
    console.log('DATASERVICE sendPricing',json);
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/createPricerAndSendEmails';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, json, { headers });
  }
  createPricingAPI(json: string, pricerId: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/generateQuoteAPIIssuers';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    const params = new HttpParams().append('pricerId', pricerId);
    return this.http.post(urlTest, json, { headers, params });
  }
  generateQuoteSociete(json: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/generateQuoteSociete';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, json, { headers });
  }
  generateQuoteBMO(json: string, marginClient: number, pricerId: string): Observable<any> {
    const urlTest = `${environment.apiUrl}/apexrest/pricermobile/generateQuoteBMO`;
    const headers = new HttpHeaders({
        'Authorization': `Bearer ${this.getSessionId()}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    const params = new HttpParams()
        .append('pricerId', pricerId)
        .append('marginClient', marginClient.toString());
    return this.http.post(urlTest, json, { headers, params });
  }
  generateQuoteSantander(json: string, pricerId: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/generateQuoteSantander';
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.getSessionId(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    const params = new HttpParams().append('pricerId', pricerId);
    return this.http.post(urlTest, json, { headers, params });
  }
  generateQuoteLeonteq(json: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/generateQuoteLeonteq';
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.getSessionId(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, json, { headers });
  }
  getPricingSantanderAPI(json: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getPriceByQuoteIdSAN ';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, json, { headers });
  }
  getPricerByQuoteIdLEO(json: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getPricerByQuoteIdLEO';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, json, { headers });
  }
  generateQuoteVontobel(pricingJSON: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/generateQuoteVontobel';
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.getSessionId(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, pricingJSON, { headers });
  }
  getPricerVontobelByTask(taskId: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getPricerVontobelByTask';
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.getSessionId(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, { taskId }, { headers });
  }

  getPricerByQuoteIdVONTOBEL(quoteId: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getPricerByQuoteIdVONTOBEL';
    const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.getSessionId(),
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, quoteId, { headers });
  }

  getPricingSocieteAPI(json: string): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/getPricerByQuoteIdSG';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.post(urlTest, json, { headers });
  }
  savePricingResult(json): Observable<any> {
    const urlTest = environment.apiUrl + '/apexrest/pricermobile/savePricer';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    //const params = new HttpParams().append('type', 'price').append('pricerId', '00001986').append('issuer', 'BARX');
    /* Object.keys(json).forEach( key => {
      params = params.append(key, json[key]);
    }); */
    //console.log(params);
    return this.http.post(urlTest, json, { headers });
  }
  getMostUsedUnderlyings(): Observable<any> {
    const mostUsed = ['SPX', 'AMZN UW', 'GOOGL UW', 'SX5E', 'TSLA UW', 'DIS UN', 'AMD UW', 'NDX', 'MSFT UW', 'META UW', 'NVDA UW', 'RTY', 'AAPL UW', 'NKY'];
    const urlTest = environment.apiUrl + '/data/v57.0/query?q=SELECT+Id,Name,Name__c,Logo__c,RICCode__c,X3M_imp_put_vol__c,Website__c+FROM+Underlying__c+WHERE+Name+IN+(\'' + mostUsed.join('\', \'') + '\')+AND+AvailableInPricer__c+=+true';
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    });
    return this.http.get(urlTest, { headers });
  }

  sendTableData(selectedResults: any): Observable<any> {
    const url = environment.apiUrl + '/apexrest/pricermobile/sharePricer';
  
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getSessionId(),
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
  
    // Verificar los datos antes de enviarlos
    console.log('Datos a enviar:', selectedResults);
  
    // Asegúrate de que la respuesta esté siendo tratada como JSON
    return this.http.post(url, selectedResults, { headers, responseType: 'json' });
  }
  
  

  private getSessionId() {
    return this.authService.token;
  }
}
