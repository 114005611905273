// salesforce-auth.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalesforceAuthService {
  private serverUrl = 'https://salesforceauthserver-141968f72a84.herokuapp.com'; // Reemplaza con la URL de tu servidor Node.js

  constructor(private http: HttpClient) {}

  internalLogin(credentials: { email: string; password: string }): Observable<any> {
    //QA URL /auth/salesforceQA
    //PROD /auth/salesforce
    const url = `${this.serverUrl}/auth/salesforceQA`;

    const body = {
      email: credentials.email,
      password: credentials.password,
    };

    const headers = new HttpHeaders({
      'content-type': 'application/json', // Cambia 'Content-Type' a 'content-type'
    });

    return this.http.post(url, body, { headers });
  }
  communityLogin(credentials: { email: string; password: string }): Observable<any> {
    const url = `${this.serverUrl}/auth/community`;
    const body = {
      email: credentials.email,
      password: credentials.password,
    };

    const headers = new HttpHeaders({
      'content-type': 'application/json', // Cambia 'Content-Type' a 'content-type'
    });

    return this.http.post(url, body, { headers });
  }
}
