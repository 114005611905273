import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, isDevMode } from '@angular/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthHTTPInterceptor } from './interceptors/auth.interceptor';
import { SearchModalPageModule } from './search-modal/search-modal.module';
import { TabsPageModule } from './tabs/tabs.module';
import { LoginPageModule } from './login/login.module';
import { HistoricModalPageModule } from './historic-modal/historic-modal.module';
import { ServiceWorkerModule } from '@angular/service-worker';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot({
          innerHTMLTemplatesEnabled: true
        }),
        AppRoutingModule,
        HttpClientModule,
        SearchModalPageModule,
        TabsPageModule,
        LoginPageModule,
        HistoricModalPageModule,
        ServiceWorkerModule.register('ngsw-worker.js?v=1', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      {provide: HTTP_INTERCEPTORS, useClass: AuthHTTPInterceptor, multi: true}],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [TranslateModule]
})
export class AppModule {}
