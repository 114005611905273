import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { Preferences } from '@capacitor/preferences';
import { DataService } from '../services/data.service';
const LANG_KEY = 'lang-id';
const UNDERLYINGS_KEY = 'und-id';

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage {
  constructor(private actionSheetCtrl: ActionSheetController, private translate: TranslateService,
    private router: Router, private authService: AuthService, private dataService: DataService) {
  }

  ngOnInit() {
    this.checkUnderlyingSuggestions();
  }
  checkUnderlyingSuggestions() {
    Preferences.get({ key: UNDERLYINGS_KEY }).then((unds) => {
      if (unds?.value) {
        const lastSearchs = JSON.parse(unds.value.toString());
        if (lastSearchs == null || lastSearchs.length < 14) {
          this.dataService.getMostUsedUnderlyings().subscribe({
            next: (res) => {
              console.log('pedidos', res.records);
              res.records.forEach(suggestedUnd => {
                if (!lastSearchs.map(und => und.Id).includes(suggestedUnd.Id)) {
                  lastSearchs.push(suggestedUnd);
                  Preferences.set({ key: UNDERLYINGS_KEY, value: JSON.stringify(lastSearchs) });
                }
              });
            },
            error: async (res) => {
              console.log('Error loading most used underlyings');
            }
          });
        }
      } else {
        this.dataService.getMostUsedUnderlyings().subscribe({
          next: (res) => {
            Preferences.set({ key: UNDERLYINGS_KEY, value: JSON.stringify(res.records) });
          },
          error: async (res) => {
            console.log('Error loading most used underlyings');
          }
        });
      }
    });

  }
  async logout() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Profile',
      buttons: [
        {
          text: 'Tutorial',
          data: {
            action: 'intro',
          }
        },
        {
          text: 'Logout',
          role: 'destructive',
          data: {
            action: 'delete',
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        }
      ],
    });

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();
    if (result?.data?.action === 'delete') {
      await this.authService.logout();
      this.router.navigateByUrl('/login', { replaceUrl: true });
    } else if (result?.data?.action === 'intro') {
      this.router.navigateByUrl('/intro', { replaceUrl: true });
    }
  }
  async chooseLanguage() {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Choose languae',
      buttons: [
        {
          text: 'English',
          /* icon: 'language-outline', */
          data: {
            lang: 'en',
          }
        },
        {
          text: 'Spanish',
          data: {
            lang: 'es',
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            lang: null,
          },
        },
      ],
    });

    await actionSheet.present();

    const result = await actionSheet.onDidDismiss();
    if (result?.data?.lang) {
      Preferences.set({ key: LANG_KEY, value: result.data.lang });
      this.translate.use(result.data.lang);
      window.location.reload();
    }
  }
}
