/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { PriceSelectorModalComponent } from '../price-selector-modal/price-selector-modal.component';
import { IonSegment, IonRouterOutlet, AlertController, ToastController, IonicSafeString, IonContent } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { SearchModalPage } from '../search-modal/search-modal.page';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { Preferences } from '@capacitor/preferences';
import { io } from 'socket.io-client';
import { Clipboard } from '@capacitor/clipboard';
import { Direct } from 'protractor/built/driverProviders';
const USER_KEY = 'user-id';
const USER_MRG = 'user-mrg';
const USER_ISS = 'user-iss';
const UNDERLYINGS_KEY = 'und-id';
const WEBSOCKET_SERVER_URL = 'wss://akouraresponsehandler-f818d0ea7dd2.herokuapp.com';
const SOLVER_FORS = ['Offer Price', 'Barrier Level', 'Barrier Level equal to Coupon Trigger', 'Coupon Trigger', 'Coupon'];
const frequencyResponse = { 'OnePerYear': 1, 'TwoPerYear': 2, 'FourPerYear': 4, 'TwelvePerYear': 12, 'InFine': 1, 'OnePerMonth': 12 };
const RESULT = { 'NULL': 0, 'NOT_AVAILABLE': 1, 'ERROR': 2, 'VALID': 3 };
export const constants = {
  issuers: {
    BBVA: 'BBVA', UBS: 'UBS', MORGANSTANLEY: 'Morgan Stanley', BNP: 'BNP', CITI: 'Citigroup', SOCIETE: 'Societe Generale',
    JPMNY: 'JP Morgan (New York)', JPMORGAN: 'JP Morgan (London)', BARX: 'Barclays', MAREX: 'Marex Financial', GOLDMANSACHS: 'Goldman Sachs',
    SWISS: 'Julius Baer'/* , CS: 'Credit Suisse' */, HSBC: 'HSBC', SANTANDER: 'Santander', LUKB: 'Luzerner Kantonalbank', BOFA: 'Bank of America', RBC: 'Royal Bank of Canada',
    CA: 'Crédit Agricole', EFG: 'EFG International Finance', LEOAG: 'Leonteq Securities AG', BIL: 'Banque Internationale A Luxembourg', RAIFFN: 'Raiffeisen Netherlands',
    BASK: 'Basler Kantonalbank', CORNB: 'Corner Bank', LEOAGP: 'PostFinance', LEOAGAKG: 'Aargauische Kantonalbank', VONTOBEL: 'Vontobel', NOMURA: 'Nomura', NATIXIS:'Natixis',
    BMO:'BMO'
  }
} as const;
export const adaptedIssuerLeonteq = {
  'EFG International Finance (Guernsey) Ltd': 'EFG',
  'Leonteq Securities AG (Guernsey Branch)': 'LEOAG',
  'Banque Internationale A Luxembourg': 'BIL',
  'Raiffeisen Netherlands (Raiffeisen Schweiz Genossenschaft Guaranteed)': 'RAIFFN',
  'Basler Kantonalbank': 'BASK',
  'Corner Bank SA': 'CORNB',
  'Leonteq Securities AG (Guernsey Branch) (PostFinance Guaranteed)': 'LEOAGP',
  'Leonteq Securities AG (Guernsey Branch) (Aargauische Kantonalbank Guaranteed)': 'LEOAGAKG'
};
export const fieldBySolveForVontobel = {
  'Offer Price': 'retropct',
  'Coupon': 'couponpa',
  'Barrier Level': 'barrier'
};
@Component({
  selector: 'app-tab2',
  templateUrl: 'tab2.page.html',
  styleUrls: ['tab2.page.scss']
})
export class Tab2Page {
  @ViewChild('mySegment') segments: IonSegment;
  @ViewChild('swiper') slides: any;
  @ViewChild(IonContent) content: IonContent;

  //Pricing form
  pricing: FormGroup;
  currentPricingPriced;
  selectedUnderlyings = [];

  //Margin and disabled issuers per user
  margins;
  marginToAdd = 0;
  disabledIssuers = [];

  //Websockets and API
  connection;
  websocketTimeOut = null;
  roomName;
  timeoutSG;
  timeoutSAN;
  quoteIdLEO;
  quoteIdVON;
  taskIdVON;


  //Pricing results
  pricerId = null;
  seeResults = false;
  results = {};//{ BBVA: null, UBS: null, BNP: null, MORGANSTANLEY: null, JPMORGAN: null, JPMNY: null, CITI: null, SOCIETE: null, GOLDMANSACHS: null, MAREX: null, SWISS: null/* , CS: null */, HSBC: null, BARX: null, SANTANDER: null, LUKB: null, BOFA: null, RBC: null, CA: null, EFG: null, LEOAG: null, BIL: null, RAIFFN: null, BASK: null, CORNB: null, LEOAGP: null, LEOAGAKG: null, VONTOBEL: null};
  readonly issuers = constants.issuers;
  //Cascade select options
  optionsByType = {
    'Express Certificate': [
      { label: this.translate.instant('FORM.SUBTYPE.CONDITIONAL'), api: 'Phoenix Autocall' },
      { label: this.translate.instant('FORM.SUBTYPE.GUARANTEED'), api: 'Reverse Convertible Autocall' }
    ],
    'Reverse Convertible': [
    { label: this.translate.instant('FORM.SUBTYPE.BRC'), api: 'Barrier Reverse Convertible' },
    { label: this.translate.instant('FORM.SUBTYPE.CCBRC'), api: 'Conditional Coupons Barrier Reverse Convertible' }  // Add this line
  ],
    'Participation': [
      { label: this.translate.instant('FORM.SUBTYPE.BOOSTER'), api: 'Booster' },
      { label: this.translate.instant('FORM.SUBTYPE.CAPITAL'), api: 'Capital Guaranteed' }
    ]
  };
  optionsBySubtype = {
    'Phoenix Autocall': {
      'solvefors': [
        { label: this.translate.instant('FORM.SOLVEFOR.COUPON'), api: 'Coupon', hide: ['couponpa', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.COUPONTRIGGER'), api: 'Coupon Trigger', hide: ['couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.BARRIER'), api: 'Barrier Level', hide: ['barrierLevel', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.BARRIERANDCOUPON'), api: 'Barrier Level equal to Coupon Trigger', hide: ['barrierLevel', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.OFFERPRICE'), api: 'Offer Price', hide: ['offerPrice', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] }
      ],
      'coupontypes': [
        { label: this.translate.instant('FORM.COUPONTYPE.WITHMEMORY'), api: 'Conditional with memory' },
        { label: this.translate.instant('FORM.COUPONTYPE.NOMEMORY'), api: 'Conditional without memory' }
      ],
      'observationfrequencies': [
        { label: this.translate.instant('FORM.OBSERVATION.MONTHLY'), api: 'Monthly' },
        { label: this.translate.instant('FORM.OBSERVATION.QUARTERLY'), api: 'Quarterly' },
        { label: this.translate.instant('FORM.OBSERVATION.SEMIANUALLY'), api: 'Semi-annually' },
        { label: this.translate.instant('FORM.OBSERVATION.ANUALLY'), api: 'Annually' }
      ]
    },
    'Reverse Convertible Autocall': {
      'solvefors': [
        { label: this.translate.instant('FORM.SOLVEFOR.COUPON'), api: 'Coupon', hide: ['couponpa', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.BARRIER'), api: 'Barrier Level', hide: ['barrierLevel', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.OFFERPRICE'), api: 'Offer Price', hide: ['offerPrice', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'capitalprotection'] }
      ],
      'coupontypes': [
        { label: this.translate.instant('FORM.COUPONTYPE.GUARANTEED'), api: 'Guaranteed' }
      ],
      'observationfrequencies': [
        { label: this.translate.instant('FORM.OBSERVATION.MONTHLY'), api: 'Monthly' },
        { label: this.translate.instant('FORM.OBSERVATION.QUARTERLY'), api: 'Quarterly' },
        { label: this.translate.instant('FORM.OBSERVATION.SEMIANUALLY'), api: 'Semi-annually' },
        { label: this.translate.instant('FORM.OBSERVATION.ANUALLY'), api: 'Annually' }
      ]
    },
    'Barrier Reverse Convertible': {
      'solvefors': [
        { label: this.translate.instant('FORM.SOLVEFOR.COUPON'), api: 'Coupon', hide: ['couponpa', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'firstCallInObs', 'autocallTrigger', 'autocallStepDown', 'onestar', 'onestarlevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.BARRIER'), api: 'Barrier Level', hide: ['barrierLevel', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'firstCallInObs', 'autocallTrigger', 'autocallStepDown', 'onestar', 'onestarlevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.OFFERPRICE'), api: 'Offer Price', hide: ['offerPrice', 'couponTrigger', 'cap', 'callStrike', 'participation', 'capLevel', 'firstCallInObs', 'autocallTrigger', 'autocallStepDown', 'onestar', 'onestarlevel', 'capitalprotection'] }
      ],
      'coupontypes': [
        { label: this.translate.instant('FORM.COUPONTYPE.GUARANTEED'), api: 'Guaranteed' }
      ],
      'observationfrequencies': [
        { label: this.translate.instant('FORM.OBSERVATION.MONTHLY'), api: 'Monthly' },
        { label: this.translate.instant('FORM.OBSERVATION.QUARTERLY'), api: 'Quarterly' },
        { label: this.translate.instant('FORM.OBSERVATION.SEMIANUALLY'), api: 'Semi-annually' },
        { label: this.translate.instant('FORM.OBSERVATION.ANUALLY'), api: 'Annually' },
        { label: this.translate.instant('FORM.OBSERVATION.MATURITY'), api: 'At Maturity' }
      ]
    },
    'Conditional Coupons Barrier Reverse Convertible': {
    'solvefors': [
      { label: this.translate.instant('FORM.SOLVEFOR.COUPON'), api: 'Coupon', hide: ['couponpa', 'cap', 'callStrike', 'participation', 'capLevel', 'firstCallInObs', 'autocallTrigger', 'autocallStepDown', 'onestar', 'onestarlevel', 'capitalprotection'] },
      { label: this.translate.instant('FORM.SOLVEFOR.BARRIER'), api: 'Barrier Level', hide: ['barrierLevel', 'cap', 'callStrike', 'participation', 'capLevel', 'firstCallInObs', 'autocallTrigger', 'autocallStepDown', 'onestar', 'onestarlevel', 'capitalprotection'] },
      { label: this.translate.instant('FORM.SOLVEFOR.OFFERPRICE'), api: 'Offer Price', hide: ['offerPrice', 'cap', 'callStrike', 'participation', 'capLevel', 'firstCallInObs', 'autocallTrigger', 'autocallStepDown', 'onestar', 'onestarlevel', 'capitalprotection'] }
    ],
    'coupontypes': [
      { label: this.translate.instant('FORM.COUPONTYPE.WITHMEMORY'), api: 'Conditional with memory' },  // Add this line
      { label: this.translate.instant('FORM.COUPONTYPE.NOMEMORY'), api: 'Conditional without memory' }  // Add this line
    ],
    'observationfrequencies': [
      { label: this.translate.instant('FORM.OBSERVATION.MONTHLY'), api: 'Monthly' },
      { label: this.translate.instant('FORM.OBSERVATION.QUARTERLY'), api: 'Quarterly' },
      { label: this.translate.instant('FORM.OBSERVATION.SEMIANUALLY'), api: 'Semi-annually' },
      { label: this.translate.instant('FORM.OBSERVATION.ANUALLY'), api: 'Annually' },
      { label: this.translate.instant('FORM.OBSERVATION.MATURITY'), api: 'At Maturity' }
    ]
  },
    'Booster': {
      'solvefors': [
        { label: this.translate.instant('FORM.SOLVEFOR.OFFERPRICE'), api: 'Offer Price', hide: ['offerPrice', 'couponpa', 'couponTrigger', 'autocallStepDown', 'autocallTrigger', 'firstCallInObs', 'observationFrequency', 'couponType', 'onestar', 'onestarlevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.BARRIER'), api: 'Barrier Level', hide: ['barrierLevel', 'couponpa', 'couponTrigger', 'autocallStepDown', 'autocallTrigger', 'firstCallInObs', 'observationFrequency', 'couponType', 'onestar', 'onestarlevel', 'capitalprotection'] },
        { label: this.translate.instant('FORM.SOLVEFOR.PARTICIPATION'), api: 'Participation', hide: ['participation', 'couponpa', 'couponTrigger', 'autocallStepDown', 'autocallTrigger', 'firstCallInObs', 'observationFrequency', 'couponType', 'onestar', 'onestarlevel', 'capitalprotection'] }
      ],
      'coupontypes': null,
      'observationfrequencies': null
    },
    'Capital Guaranteed': {
      'solvefors': [
        { label: this.translate.instant('FORM.SOLVEFOR.OFFERPRICE'), api: 'Offer Price', hide: ['barrierLevel', 'offerPrice', 'couponpa', 'couponTrigger', 'autocallStepDown', 'autocallTrigger', 'firstCallInObs', 'observationFrequency', 'couponType', 'onestar', 'onestarlevel', 'barrierType', 'putStrike', 'downsideLeverage', 'leveraged'] }
      ],
      'coupontypes': null,
      'observationfrequencies': null
    }
  };
  frequencies = { 'Annually': 12, 'Semi-annually': 6, 'Quarterly': 3, 'Monthly': 1, 'At Maturity': null };

  //Select default options
  types = {
    label: this.translate.instant('FORM.TYPE_LABEL'),
    options: [
      { label: this.translate.instant('FORM.TYPE.AUTOCALL'), api: 'Express Certificate' },
      { label: this.translate.instant('FORM.TYPE.REVERSE'), api: 'Reverse Convertible' },
      { label: this.translate.instant('FORM.TYPE.PARTICIPATION'), api: 'Participation' }
    ]
  };
  subtypes = {
    label: this.translate.instant('FORM.SUBTYPE_LABEL'),
    options: this.optionsByType['Express Certificate']
  };
  currencies = {
    label: this.translate.instant('FORM.CCY_LABEL'),
    options: [
      { label: this.translate.instant('FORM.CCY.USD'), api: 'USD' },
      { label: this.translate.instant('FORM.CCY.EUR'), api: 'EUR' },
      { label: this.translate.instant('FORM.CCY.CHF'), api: 'CHF' },
      { label: this.translate.instant('FORM.CCY.GBP'), api: 'GBP' },
      { label: this.translate.instant('FORM.CCY.AUD'), api: 'AUD' },
      { label: this.translate.instant('FORM.CCY.CAD'), api: 'CAD' },
      { label: this.translate.instant('FORM.CCY.DKK'), api: 'DKK' },
      { label: this.translate.instant('FORM.CCY.HKD'), api: 'HKD' },
      { label: this.translate.instant('FORM.CCY.JPY'), api: 'JPY' },
      { label: this.translate.instant('FORM.CCY.MXN'), api: 'MXN' },
      { label: this.translate.instant('FORM.CCY.NOK'), api: 'NOK' },
      { label: this.translate.instant('FORM.CCY.SEK'), api: 'SEK' },
      { label: this.translate.instant('FORM.CCY.SGD'), api: 'SGD' },
      { label: this.translate.instant('FORM.CCY.RUB'), api: 'RUB' },
      { label: this.translate.instant('FORM.CCY.ILS'), api: 'ILS' }
    ]
  };
  solvefors = {
    label: this.translate.instant('FORM.SOLVEFOR_LABEL'),
    options: this.optionsBySubtype['Phoenix Autocall'].solvefors
  };
  coupontypes = {
    label: this.translate.instant('FORM.COUPONTYPE_LABEL'),
    options: this.optionsBySubtype['Phoenix Autocall'].coupontypes
  };
  barriers = {
    label: this.translate.instant('FORM.BARRIER_LABEL'),
    options: [
      { label: this.translate.instant('FORM.BARRIER.EUROPEAN'), api: 'European' },
      { label: this.translate.instant('FORM.BARRIER.AMERICAN'), api: 'American' },
      { label: this.translate.instant('FORM.BARRIER.DAILY'), api: 'Daily Close' }
    ]
  };
  observationfrequencies = {
    label: this.translate.instant('FORM.OBSERVATION_LABEL'),
    options: this.optionsBySubtype['Phoenix Autocall'].observationfrequencies
  };
  firstcallins = [1];

  //Pricing form visibility
  compulsary = ['productRecordType', 'productSubtype', 'solveFor', 'isoCurrency', 'delivery'];
  visible = {
    offerPrice: true, putStrike: true, leveraged:true, downsideLeverage: true, barrierType: true, barrierLevel: true, onestar: true, onestarlevel: true, capitalprotection: false,
    couponpa: false, couponTrigger: true, couponType: true, observationFrequency: true, firstCallInObs: true, autocallTrigger: true, autocallStepDown: true, callStrike: false, participation: false, cap: false, capLevel: false
  };

  constructor(private modalCtrl: ModalController, private translate: TranslateService, private dataService: DataService, private route: ActivatedRoute,
    private fb: FormBuilder, private cdr: ChangeDetectorRef, private alertCtrl: AlertController, private toastCtrl: ToastController, private router: Router) {
    this.pricing = this.fb.group({
      productRecordType: ['Express Certificate', [Validators.required]],
      productSubtype: ['Phoenix Autocall', [Validators.required]],
      solveFor: ['Coupon', [Validators.required]],
      notionalAmount: [400000, [Validators.required]],
      isoCurrency: ['USD', [Validators.required]],
      maturity: [24, [Validators.required]],
      offerPrice: [98],
      issuePrice: 100,
      delivery: ['Cash'],
      putStrike: [100],
      leveraged: ['Yes'],
      downsideLeverage: [{ value: 100, disabled: true }],
      barrierType: ['European'],
      barrierLevel: [70],
      onestar: ['No'],
      onestarlevel: [{ value: null, disabled: true }],
      capitalprotection: [100],
      couponpa: [null],
      couponTrigger: [70],
      couponType: ['Conditional with memory'],
      observationFrequency: ['Annually'],
      firstCallInObs: [1],
      autocallTrigger: [100],
      autocallStepDown: [0],
      callStrike: [100],
      participation: [100],
      cap: ['No'],
      capLevel: [{ value: null, disabled: true }]
    });
    this.route.queryParams.subscribe(params => {
      const state = this.router.getCurrentNavigation().extras.state;
      if (state && state.pricing) {
        this.setPricing(state.pricing);
      }
    });
  }
  ngOnInit(): void {
    this.getIssuer();
  }
  async getIssuer() {
    const margins = await Preferences.get({ key: USER_MRG });
    this.margins = JSON.parse(margins.value);
    const issuers = await Preferences.get({ key: USER_ISS });
    const issuersValue: { status; data } = JSON.parse(issuers.value);


    Object.keys(this.issuers).forEach(key => {
      if (issuersValue?.status === 'OK') {
        if (!issuersValue.data[`Disable${key}__c`]) {
          this.results[key] = null;
        }
      } else {
        this.results[key] = null;
      }

    });
  }

  //** FORM EVENTS */
  onSegmentChange(ev: any) {
    this.slides.nativeElement.swiper.slideTo(+ev.detail.value);
    this.content.scrollToTop();
  }
  onSlideChange(ev: any) {
    this.segments.value = String(ev.detail[0].activeIndex);
    this.content.scrollToTop(400);
  }
  onTypeChange(e): void {
    const type = e.detail.value;
    this.onTypeChangeInternal(type);
  }
  onTypeChangeInternal(type): void {
    this.subtypes.options = this.optionsByType[type];
    const subtype = this.optionsByType[type][0].api;
    this.pricing.controls.productSubtype.setValue(subtype);
    this.onSubTypeChangeInternal(subtype);
  }
  onSubTypeChange(e): void {
    const subtype = e.detail.value;
    this.onSubTypeChangeInternal(subtype);
  }
  onSubTypeChangeInternal(subtype): void {
    this.solvefors.options = this.optionsBySubtype[subtype].solvefors;
    const solveFor = this.optionsBySubtype[subtype].solvefors[0].api;
    this.pricing.controls.solveFor.setValue(solveFor);
    this.onSolveForChangeIntenal(solveFor);

    if (this.optionsBySubtype[subtype].coupontypes) {
      this.coupontypes.options = this.optionsBySubtype[subtype].coupontypes;
      this.pricing.controls.couponType.setValue(this.optionsBySubtype[subtype].coupontypes[0].api);
    }
    if (this.optionsBySubtype[subtype].observationfrequencies) {
      this.observationfrequencies.options = this.optionsBySubtype[subtype].observationfrequencies;
      this.pricing.controls.observationFrequency.setValue(this.optionsBySubtype[subtype].observationfrequencies[0].api);
    }

    /* this.recalculateHidenFields(subtype, solvefor); */
    //this.cdr.detectChanges();
  }
  onLeveragedChange(e): void {
    const leveraged = e.detail.value === 'Yes';
    this.onLeveragedChangeInternal(leveraged);
  }
  onLeveragedChangeInternal(leveraged): void {
    if (leveraged) {
      const putStrike = this.pricing.controls.putStrike.value;
      this.pricing.controls.downsideLeverage.setValue(+(10000 / putStrike).toFixed(2));
    } else {
      this.pricing.controls.downsideLeverage.setValue(null);
    }
  }
  onSolveForChange(e): void {
    const solvefor = e.detail.value;
    this.onSolveForChangeIntenal(solvefor);
  }
  onSolveForChangeIntenal(solvefor): void {
    this.recalculateHidenFields(this.pricing.controls.productSubtype.value, solvefor);
  }
  onMaturityChange(e): void {
    const maturity = e.detail.value;
    this.onMaturityChangeInternal(maturity);
  }
  onMaturityChangeInternal(maturity): void {
    const frequency = this.frequencies[this.pricing.controls.observationFrequency.value];
    this.recalculateFirstCallIn(maturity, frequency);
    //this.cdr.detectChanges();
  }
  onObservationFrequencyChange(e): void {
    const freq = e.detail.value;
    this.onObservationFrequencyChangeInternal(freq);
  }
  onObservationFrequencyChangeInternal(freq): void {
    const maturity = this.pricing.controls.maturity.value;
    const frequency = this.frequencies[freq];
    this.recalculateFirstCallIn(maturity, frequency);
  }
  onPutStrikeChange(e): void {
    const putStrike = e.detail.value;
    this.onPutStrikeChangeInternal(putStrike);
  }
  onPutStrikeChangeInternal(putStrike): void {
    if (this.pricing.controls.leveraged.value === 'Yes') {
      this.pricing.controls.downsideLeverage.setValue(+(10000 / putStrike).toFixed(2));
    } else {
      this.pricing.controls.downsideLeverage.setValue(null);
    }
  }
  onOneStarChange(e): void {
    const onestar = e.detail.value;
    this.onOneStarChangeInternal(onestar);
  }
  onOneStarChangeInternal(onestar): void {
    if (onestar === 'No') {
      this.pricing.controls.onestarlevel.disable();
      this.pricing.controls.onestarlevel.setValue(null);
    } else {
      this.pricing.controls.onestarlevel.setValue(110);
      this.pricing.controls.onestarlevel.enable();
    }
    //this.cdr.detectChanges();
  }
  onCapChange(e): void {
    const cap = e.detail.value;
    this.onCapChangeInternal(cap);
  }
  onCapChangeInternal(cap): void {
    if (cap === 'No') {
      this.pricing.controls.capLevel.disable();
      this.pricing.controls.capLevel.setValue(null);
    } else {
      this.pricing.controls.capLevel.setValue(120);
      this.pricing.controls.capLevel.enable();
    }
    //this.cdr.detectChanges();
  }
  recalculateHidenFields(subtype, solvefor): void {
    const options = this.optionsBySubtype[subtype].solvefors.find(x => x.api === solvefor);
    // eslint-disable-next-line guard-for-in
    for (const key in this.visible) {
      this.visible[key] = !options.hide.includes(key);
    }
  }
  recalculateFirstCallIn(maturity, frequency): void {
    if (frequency) {
      const numObs = maturity / frequency;
      this.firstcallins = [];
      for (let i = 1; i < numObs; i++) {
        this.firstcallins.push(i);
      }
    } else {
      this.firstcallins = [1];
    }
  }

  //* SEND PRICING (API) */
  createPricing() {
    Object.keys(this.visible).forEach(key => {
      if (this.visible[key]) {
        if (!this.pricing.controls[key].disabled) {
          this.pricing.controls[key].setValidators([Validators.required]);
        } else {
          this.pricing.controls[key].clearValidators();
        }
      } else {
        this.pricing.controls[key].clearValidators();
      }
      this.pricing.controls[key].updateValueAndValidity(); //Revealuate all fields
    });
    if (!this.pricing.invalid) {
      this.pricerId = null;
      Object.keys(this.results).forEach(key => this.results[key] = null);
      this.closeConnections();
      this.seeResults = true;
      const jsonValues = this.covertFormToJSON(this.pricing, this.selectedUnderlyings);
      this.currentPricingPriced = jsonValues;
      this.recalculateMargin();
      this.sendPricing(jsonValues);
      this.segments.value = '1';
      this.slides.nativeElement.swiper.slideTo(1);
      this.content.scrollToTop(400);
      this.slides.nativeElement.swiper.allowTouchMove = true;
    } else {
      let invalidFields = '';
      Object.keys(this.pricing.controls).forEach(x => { if (this.pricing.controls[x].invalid) { invalidFields += x + ' '; } });
      this.presentToast(this.translate.instant('FORM.REQUIRED') + invalidFields);
    }
  }
  async sendPricing(pricingJSON) {
    this.dataService.sendPricing(pricingJSON).subscribe({
      next: (res) => {
        if (res && res.NameLetter) {
          this.pricerId = res.NameLetter;
          this.sendPricingAPI(pricingJSON, res.Name);
          this.startWebsocket(res.NameLetter);
          this.setLimit();
        }
        if (res && res.issuerErrors) {
          console.log('issuerErrors ', res.issuerErrors);
          const issuerNA = JSON.parse(res.issuerErrors);
          issuerNA.forEach(i => {
              if (i.invalid) {
                  if (i.issuer === "LEONTEQ") {
                      // Iterate over each entry in the adaptedIssuerLeonteq map
                      for (const [key, value] of Object.entries(adaptedIssuerLeonteq)) {
                        this.insertResult(value, RESULT.NOT_AVAILABLE, 'N/A', i.errorMsg);
                      }
                  } else {
                      // Default behavior for other issuers
                      this.insertResult(i.issuer, RESULT.NOT_AVAILABLE, 'N/A', i.errorMsg);
                  }
              }
          });
      }
      },
      error: async (res) => {
        console.log('error', res);
        if (res?.status !== 401) {
          const alert = await this.alertCtrl.create({
            header: this.translate.instant('FORM.ERROR_SENDING'),
            message: res.error,
            buttons: [this.translate.instant('FORM.CLOSE')]
          });

          await alert.present();
        }
      }
    });
  }

  /**PRICERS API */
  sendPricingAPI(pricingJSON, pricerId) {
    this.generateQuoteSociete(pricingJSON);
    this.generateQuoteSantander(pricingJSON, pricerId);
    this.generateQuoteLeonteq(pricingJSON);
    this.generateQuoteVontobel(pricingJSON);
    this.generateQuoteBMO(pricingJSON, this.marginToAdd, pricerId);
  }
  generateQuoteBMO(pricingJSON, marginClient, pricerId) {
    this.dataService.generateQuoteBMO(pricingJSON, marginClient, pricerId).subscribe({
        next: (bmoResponse) => {
            console.log('BMO response:', bmoResponse);
        },
        error: (res) => {
            this.insertResult('BMO', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_BMO'));
            console.log('error', res);
        }
    });
  }
  generateQuoteSociete(pricingJSON) {
    this.dataService.generateQuoteSociete(pricingJSON).subscribe({
        next: (response) => {
            //console.log('quoteIdSG ', response);
            this.recallGetPriceSG(response.quoteId);
        },
        error: async (res) => {
            this.insertResult('SOCIETE', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_SG'));
            console.log('error', res);
        }
    });
  }
  generateQuoteVontobel(pricingJSON) {
    this.dataService.generateQuoteVontobel(pricingJSON).subscribe({
        next: (vontobelResponse) => {
            try {
                let errorMessage;
                let isError = false;

                let quoteData;
                if (typeof vontobelResponse.quoteId === 'string') {
                    try {
                        quoteData = JSON.parse(vontobelResponse.quoteId);
                    } catch (e) {
                        quoteData = vontobelResponse.quoteId;
                    }
                } else {
                    quoteData = vontobelResponse.quoteId;
                }

                if (quoteData && quoteData.error) {
                    if (quoteData.message) {
                        const messageData = JSON.parse(quoteData.message);
                        errorMessage = messageData.displayMessage || 'Unknown error';

                        if (errorMessage.includes('Could not retrieve underlying')) {
                            errorMessage = 'Underlying not available';
                        }

                        const simulationId = messageData.simulationId || null;
                        this.quoteIdVON = simulationId; 
                        this.handleVontobelResponse(simulationId); 
                    } else {
                        errorMessage = 'Unknown error';
                    }
                    isError = true;
                } else {
                    this.quoteIdVON = quoteData; // Asignar directamente si no hay error
                    this.handleVontobelResponse(quoteData); // Manejar respuesta exitosa
                }

                console.log('vontobelResponse ', quoteData);

                if (isError) {
                    const element = {
                        value: null,
                        result: null,
                        issuer: 'VONTOBEL',
                        pricerId: this.pricerId,
                        errorMsg: errorMessage,
                        error: true,
                        quoteId: this.quoteIdVON // Aquí usar el simulationId o null
                    };
                    this.insertResult('VONTOBEL', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), errorMessage, element);
                }
            } catch (error) {
                console.error('Error parsing Vontobel response:', error);
                const element = {
                    value: null,
                    result: null,
                    issuer: 'VONTOBEL',
                    pricerId: this.pricerId,
                    errorMsg: this.translate.instant('FORM.ERROR_VON'),
                    error: true,
                    quoteId: null
                };
                this.insertResult('VONTOBEL', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_VON'), element);
            }
        },
        error: async (res) => {
            const element = {
                value: null,
                result: null,
                issuer: 'VONTOBEL',
                pricerId: this.pricerId,
                errorMsg: this.translate.instant('FORM.ERROR_SENDING'),
                error: true,
                quoteId: null
            };
            this.insertResult('VONTOBEL', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_SENDING'), element);
            console.log('error', res);
        }
    });
}
  handleVontobelResponse(quoteId) {
    this.dataService.getPricerByQuoteIdVONTOBEL(quoteId).subscribe({
      next: (res) => {
        if (res.task_id) {
          setTimeout(() => {
            this.taskIdVON = res.task_id;
            this.quoteIdVON = quoteId;
            this.getPricerVontobelByTask();
          }, 10000);
        }
      },
      error: (err) => {
        console.error('Vontobel error: ', err);
        const element = {
          value: null,
          result: null,
          issuer: 'VONTOBEL',
          pricerId: this.pricerId,
          errorMsg: 'Error fetching Vontobel quote',
          error: true,
          quoteId
        };
        this.insertResult('VONTOBEL', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), 'Error fetching Vontobel quote', element);
      }
    });
  }
  getPricerVontobelByTask() {
    this.dataService.getPricerVontobelByTask(this.taskIdVON).subscribe({
      next: (res) => {
        console.log('result Vontobel TASK ', res);
        if (res.state === 'PENDING') {
          setTimeout(() => {
            this.getPricerVontobelByTask();
          }, 10000);
        } else if (res.error) {
          const jsonError = JSON.parse(res.message);
          let errorMessages;
          if (jsonError.validationMessages) {
            errorMessages = this.generateErrorMessages(jsonError.validationMessages);
          } else if (jsonError.displayMessage) {
            errorMessages = jsonError.displayMessage;
          } else {
            errorMessages = 'Unknown error';
          }
          const element = {
            value: null,
            result: null,
            issuer: 'VONTOBEL',
            pricerId: this.pricerId,
            errorMsg: errorMessages,
            error: true,
            quoteId: res.quoteId
          };
          this.insertResult('VONTOBEL', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), errorMessages, element);
        } else {
          this.processVontobelResult(res, this.taskIdVON);
        }
      },
      error: (err) => {
        console.error('Vontobel error: ', err);
        const element = {
          value: null,
          result: null,
          issuer: 'VONTOBEL',
          pricerId: this.pricerId,
          errorMsg: 'Error fetching Vontobel quote',
          error: true,
          quoteId: null
        };
        this.insertResult('VONTOBEL', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), 'Error fetching Vontobel quote', element);
      }
    });
  }
  processVontobelResult(item, quoteId) {
    const solveForSelected = this.currentPricingPriced?.solveFor[0]?.value;
    const isError = item.error !== undefined;
    let value = isError ? null : (parseFloat(item[fieldBySolveForVontobel[solveForSelected]]) * 100).toFixed(2);
    if (!isError && 'Offer Price' === solveForSelected) {
      value = (100 - ((parseFloat(item[fieldBySolveForVontobel[solveForSelected]]) + this.marginToAdd) * 100)).toFixed(2);
    }

    const errorMsg = isError ? item.error : undefined;
    const element = {
      value,
      result: isError ? null : value,
      issuer: 'VONTOBEL',
      pricerId: this.pricerId,
      errorMsg,
      error: isError,
      quoteId
    };
    this.insertResult('VONTOBEL', isError ? RESULT.ERROR : RESULT.VALID, value, isError ? errorMsg:quoteId, element);
  }
  generateErrorMessages(errors) {
    const errorMessages = errors.map((error, index) => {
        const errorDescription = error.errorDescription ? error.errorDescription : error.message;
        const minValue = error?.errorInfo?.context?.boundaries?.minValue;
        const maxValue = error?.errorInfo?.context?.boundaries?.maxValue;

        let errorMessage = `${index + 1}. ${errorDescription}`;
        if (minValue !== undefined && maxValue !== undefined) {
            errorMessage += ` (Min: ${minValue}, Max: ${maxValue})`;
        }
        return errorMessage;
    });

    return errorMessages.join('\n');
  }
  generateQuoteSantander(pricingJSON, pricerId) {
    this.dataService.generateQuoteSantander(pricingJSON, pricerId).subscribe({
        next: (quoteIdSAN) => {
            //console.log('quoteIdSAN ', quoteIdSAN);
            if (quoteIdSAN.quoteId) {
                this.recallGetPriceSantander(quoteIdSAN.quoteId);
            } else {
                console.error('Quote ID is null or undefined');
                this.insertResult('SANTANDER', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), 'Quote ID is null or undefined');
            }
        },
        error: async (res) => {
            this.insertResult('SANTANDER', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_SAN'));
            console.log('error', res);
        }
    });
  }
  generateQuoteLeonteq(pricingJSON) {
    this.dataService.generateQuoteLeonteq(pricingJSON).subscribe({
        next: (leonteqResponse) => {
            //console.log('leonteqResponse ', leonteqResponse);
            this.quoteIdLEO = leonteqResponse.quoteId;
            this.handleLeonteqResponse(leonteqResponse.quoteId);
        },
        error: async (res) => {
            this.insertResult('LEONTEQ', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_LEO'));
            console.log('error', res);
        }
    });
  }
  handleLeonteqResponse(leonteqResponse) {
    const response = JSON.parse(leonteqResponse);
    //console.log('response Leo ', response);

    this.dataService.getPricerByQuoteIdLEO(leonteqResponse).subscribe({
        next: (res) => {
            //console.log('Leonteq get : ', leonteqResponse);
            //console.log('Leonteq result: ', res);
            const expectMoreResults = res.expectMoreResults || (res.response && res.response.expectMoreResults);

            if (expectMoreResults) {
                setTimeout(() => {
                    this.handleLeonteqResponse(this.quoteIdLEO); 
                }, 5000);
            } else {
                const receivedIssuers = new Set();

                if (res.result && res.result.length > 0) {
                    res.result.forEach(item => {
                        const legalEntityName = item.legalEntityNames;
                        if (adaptedIssuerLeonteq[legalEntityName]) {
                            receivedIssuers.add(legalEntityName);
                            this.processResultItem(item, legalEntityName);
                        }
                    });

                    if (res.result.length < Object.keys(adaptedIssuerLeonteq).length) {
                        const noReceivedIssuers = new Set();
                        Object.keys(adaptedIssuerLeonteq).forEach(issuer => {
                            if (!receivedIssuers.has(issuer)) {
                                noReceivedIssuers.add(issuer);
                            }
                        });
                        this.handleErrorLeonteq('Server no response', noReceivedIssuers);
                    }
                } else {
                    this.handleErrorLeonteq('No Results');
                }
            }
        },
        error: (err) => {
            console.error('Leonteq error: ', err);
            this.handleErrorLeonteq('Error fetching Leonteq quote');
        }
    });
  }
  processResultItem(item, legalEntityName) {
    const issuerName = adaptedIssuerLeonteq[legalEntityName];
    const isError = item.error !== undefined;
    const errorMsg = isError ? item.error : undefined;
    let value = null;
    if (!isError) {
      value = parseFloat(item.solvedValue).toFixed(2);
      if ('Offer Price' === this.currentPricingPriced?.solveFor[0]?.value) {
        value = (100 - (item.solvedValue + this.marginToAdd)).toFixed(2);
      }
    }
    //console.log('item ',item);
    const element = {
      value,
      result: isError ? null : value,
      issuer: issuerName,
      pricerId: this.pricerId,
      errorMsg,
      error: isError,
      quoteId: item.quoteId
    };
    console.log('isError leonteq ',isError);
    this.insertResult(
      issuerName,
      isError ? RESULT.ERROR : RESULT.VALID,
      isError ? this.translate.instant('FORM.ERROR_RESULT') : value,
      isError ? errorMsg: item.quoteId,
      element
    );
  }
  handleErrorLeonteq(error, issuers = null) {
    Object.keys(adaptedIssuerLeonteq).forEach(issuerName => {
        const key = adaptedIssuerLeonteq[issuerName];
        if (issuers === null || issuers.has(issuerName)) {
            this.insertResult(key, RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), error);
        }
    });
  }
  recallGetPriceSG(requestParams) {
    this.timeoutSG = setTimeout(() => {
      if (this.results['SOCIETE'] === null) {
        this.getPriceSG(requestParams);
      }
    }, 30000);
  }
  recallGetPriceSantander(requestParams) {
    this.timeoutSAN = setTimeout(() => {
      if (this.results['SANTANDER'] === null) {
        this.getPriceSantander(requestParams);
      }
    }, 30000);
  }
  getPriceSG(societeJSON) {
    this.dataService.getPricingSocieteAPI(societeJSON).subscribe({
      next: (res) => {
        if ((res?.quoteInfoResponse?.data?.solveResult === 0 || res?.quoteInfoResponse?.data?.solveResult === null) && res?.quoteInfoResponse?.status === 'Accepted') {
          console.log('SG not ready yet, recall');
          this.recallGetPriceSG(societeJSON);
        } else if (res?.quoteInfoResponse?.status === 'Rejected') {
          console.log('SG rejected');
          const element = { value: null, result: null, issuer: 'SOCIETE', pricerId: this.pricerId, errorMsg: 'Server rejected', error: true, quoteId: res?.quoteInfoResponse?.data?.quoteId };
          this.insertResult('SOCIETE', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), 'Server rejected', element);
        } else if (res?.quoteInfoResponse?.status === 'Failed') {
          console.log('SG failed');
          const element = { value: null, result: null, issuer: 'SOCIETE', pricerId: this.pricerId, errorMsg: 'Server failed', error: true, quoteId: res?.quoteInfoResponse?.data?.quoteId };
          this.insertResult('SOCIETE', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), 'Server failed', element);
        } else if (res?.quoteInfoResponse?.status === 'Quoted') {
          console.log('SG quoted');
          //TODO Margin and solver for coupon
          let valueIfCouponPA = res?.quoteInfoResponse?.data?.solveFor === 'ConditionalAndRecallCoupons' ? this.convertToCouponPA(res?.quoteInfoResponse?.data?.solveResult, res?.quoteInfoResponse?.data?.quote?.couponFrequency, res?.quoteInfoResponse?.data?.quote?.maturityValue?.currentValue?.value) : res?.quoteInfoResponse?.data?.solveResult;
          if (valueIfCouponPA) {
            valueIfCouponPA = Number(valueIfCouponPA) + this.marginToAdd;
          }

          const element = { value: valueIfCouponPA, result: valueIfCouponPA, issuer: 'SOCIETE', pricerId: this.pricerId, errorMsg: null, error: false, quoteId: res?.quoteInfoResponse?.data?.quoteId };
          this.insertResult('SOCIETE', RESULT.VALID, valueIfCouponPA, res?.quoteInfoResponse?.data?.quoteId, element);
        }
      },
      error: async (res) => {
        this.insertResult('SOCIETE', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_SG'));
        console.log('error', res);
        /* const alert = await this.alertCtrl.create({
          header: this.translate.instant('FORM.ERROR_SG'),
          message: res.error,
          buttons: [this.translate.instant('FORM.CLOSE')]
        });

        await alert.present(); */
      }
    });
  }
  getPriceSantander(sanJSON) {
    this.dataService.getPricingSantanderAPI(sanJSON).subscribe({
      next: (res) => {
        if ((!res?.quoteResponse?.error || res?.quoteResponse?.error === '') || (res?.quoteResponse?.error && res?.quoteResponse?.httpStatusCode === 1)) {
          if (res?.quoteResponse?.jobState === 'Pending') {
            console.log('SAN not ready yet, recall');
            this.recallGetPriceSantander(sanJSON);
          } else {
            console.log('SAN value', res?.quoteResponse?.value);
            const valueWithMargin = Number(res?.quoteResponse?.value) + this.marginToAdd;
            const element = { value: valueWithMargin, result: valueWithMargin, issuer: 'SANTANDER', pricerId: this.pricerId, errorMsg: null, error: false, quoteId: res?.quoteResponse?.id };
            this.insertResult('SANTANDER', RESULT.VALID, valueWithMargin, res?.quoteResponse?.id, element);
          }
        } else {
          console.log('SAN error, httpcode', res?.quoteResponse?.httpStatusCode);
          const element = { value: null, result: null, issuer: 'SANTANDER', pricerId: this.pricerId, errorMsg: res?.quoteResponse?.error, error: true, quoteId: res?.quoteResponse?.id };
          this.insertResult('SANTANDER', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), res?.quoteResponse?.error, element);
        }
      },
      error: async (res) => {
        this.insertResult('SANTANDER', RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_SAN'));
        console.log('error', res);
        /* const alert = await this.alertCtrl.create({
          header: this.translate.instant('FORM.ERROR_SAN'),
          message: res.error,
          buttons: [this.translate.instant('FORM.CLOSE')]
        });

        await alert.present(); */
      }
    });
  }
  convertToCouponPA(originalValue, frequency, maturity) {
    let result;
    if (frequency === 'InFine') {
      result = maturity / 12;
      result = originalValue / result;
    } else {
      result = frequencyResponse[frequency] * originalValue;
    }
    return result;
  }

  //* RESULTS (Websockets) */
  startWebsocket(requestName) {
    this.connection = io(WEBSOCKET_SERVER_URL, {
      path: '/socket',
      transports: ['websocket'],
      reconnectionAttempts: 21,
      timeout: 20000
    });
    if (this.connection) {
      this.connection.emit('joinRoom', requestName);
      console.log('La sala de conexión es ', requestName);
      this.roomName = requestName;
    } else {
      console.log('this.connection no estaba listo');
    }

    this.connection.on('connect', () => {
      this.handleConnectionEstablished();
    });

    this.connection.on('connectionLost', () => {
      this.handleConnectionLost();
    });


    this.connection.on('notification', (message) => {
      this.handleNotification(message);
    });
    this.connection.on('reconnection_attempt', () => {
      this.handleConnectionLost();
    });

    this.connection.io.on('reconnect', () => {
      this.handleReconnectionEstablished();
    });

    this.connection.on('error', (error) => {
      this.handleConnectionError(error);
    });

    this.connection.on('connect_error', (error) => {
      this.handleConnectionError(error);
    });
    this.connection.on('roomClosed', (data) => {
      console.log(data.msg);
    });
  }
  handleConnectionEstablished() {
    console.log('La conexión WebSocket se ha establecido correctamente.');
  }

  handleConnectionLost() {
    console.log('La conexión WebSocket se ha perdido. Intentando reconectar...');
    // Realizar acciones adicionales cuando se pierde la conexión
  }

  handleNotification(message) {
    console.log('Mensaje recibido del servidor:', message);
    this.treatResult(message);
  }
  handleReconnectionEstablished() {
    console.log('La conexión WebSocket se ha reestablecido correctamente.');
    this.openRoom(this.roomName);
    this.dataService.getResultsFromPricer(this.roomName.slice(0, -1)).subscribe({
      next: (res) => {
        console.log('missing results', res);
        if (res?.records && res?.records[0] && res?.records[0].Pricer_price_by_banks__r?.records.length > 0) {
          res.records[0].Pricer_price_by_banks__r.records.forEach(element => {
            if (this.results[element?.Issuer__r?.PricerId__c] == null) {
              console.log('It does not exist');
              let valueWithMargin = element.Result__c;
              if (!element.Error__c && this.currentPricingPriced?.solveFor[0]?.value === SOLVER_FORS[0]) {
                if (valueWithMargin !== null) {
                  valueWithMargin = Number(valueWithMargin) + this.marginToAdd;
                }
              }
              const results = element.Error__c ? this.translate.instant('FORM.ERROR_RESULT') : valueWithMargin;
              const subtext = element.Error__c ? element.ErrorMessage__c : element.IssuerId__c;
              const code = element.Error__c ? RESULT.ERROR : RESULT.VALID;
              this.insertResult(element?.Issuer__r?.PricerId__c, code, results, subtext);
            } else {
              console.log('It already exists ', this.results[element?.Issuer__r?.PricerId__c]);
            }
          });
        } else {
          console.log('Not new results');
        }

      },
      error: (res) => {
        console.log('error', res);
      }
    });
  }
  handleConnectionError(error) {
    console.log('Error de conexión WebSocket:', error);
  }
  openRoom(roomName) {
    if (roomName) {
      this.connection.emit('joinRoom', roomName);
      console.log('La sala de conexión es ', roomName);
      this.roomName = roomName;
    }

  }
  treatResult(msg) {
    const parsedMsg = msg;
    if (parsedMsg?.issuer && this.results[parsedMsg.issuer] === null) {
      // Verifica que 'parsedMsg.result' exista y sea un array con al menos un elemento
      if (parsedMsg.result && Array.isArray(parsedMsg.result) && parsedMsg.result.length > 0) {
        const element = parsedMsg.result[0];
        let valueWithMargin = element.value;
        if (!element.error && this.currentPricingPriced?.solveFor[0]?.value === SOLVER_FORS[0]) {
          if (valueWithMargin !== null) {
            valueWithMargin = Number(valueWithMargin) + this.marginToAdd;
          }
        }
        const result = element.error ? this.translate.instant('FORM.ERROR_RESULT') : valueWithMargin;
        const subtext = element.error ? element.errorMsg : element.quoteId;
        const code = element.error ? RESULT.ERROR : RESULT.VALID;
        element.pricerId = this.pricerId;
        element.issuer = parsedMsg.issuer;
        element.result = valueWithMargin;
        element.value = valueWithMargin;
        console.log('insert element', element);
        this.insertResult(parsedMsg.issuer, code, result, subtext, element);
      } else {
        const errorMessage = this.translate.instant('FORM.ERROR_RESULT_MSG', { issuer: parsedMsg.issuer });
        console.error(errorMessage);

        this.insertResult(parsedMsg.issuer, RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), errorMessage);
      }
    }
  }
  recalculateMargin() {
    let marginValue = 0;
    if (this.margins && 'Offer Price' === this.currentPricingPriced?.solveFor[0]?.value) {
      const value = this.margins.find(x => x.ProductRecordType__c === this.currentPricingPriced?.productRecordType[0]?.value);
      marginValue = value?.Margin__c !== null ? value?.Margin__c : 0;
    }
    this.marginToAdd = Number(marginValue);
  }
  insertResult(issuer, type, result, subtext, sendToServer = null) {
    try {
      if (type === RESULT.VALID) {
        if (result !== 0 && result !== null && result !== undefined) {
          result = Number(result).toFixed(2) + '%';
        } else {
          type = RESULT.ERROR;
          result = this.translate.instant('FORM.ERROR_RESULT');
          subtext = this.translate.instant('FORM.ERROR_INVALID');
        }
      }
      if (this.results[issuer] === null) {
        this.results[issuer] = { result, subtext, type };

        if (sendToServer !== null) {
          this.dataService.savePricingResult(sendToServer).subscribe({
            error: (res) => {
              console.log('error', res);
            }
          });
        }
      }
    } catch (e) {
      console.log('error inserting price', e);
    }
  }
  ordered = (a, b) => {
    const codeA = a?.value?.type ? a?.value?.type : 0;
    const codeB = b?.value?.type ? b?.value?.type : 0;
  
    // Prioridad para resultados válidos
    const isValidA = codeA === 3;
    const isValidB = codeB === 3;
  
    // Prioridad para resultados aún buscando (null o undefined)
    const isSearchingA = a?.value?.result === null || a?.value?.result === undefined;
    const isSearchingB = b?.value?.result === null || b?.value?.result === undefined;
  
    // Errores y N/A
    const isErrorA = codeA === 2;
    const isErrorB = codeB === 2;
  
    const isNAA = codeA === 1;
    const isNAB = codeB === 1;
  
    // 1. Primero los válidos (ordenados según el valor de result)
    if (isValidA && isValidB) {
      const resultA = parseFloat(a?.value?.result);
      const resultB = parseFloat(b?.value?.result);
      
      // Si el solveFor es un valor específico, cambiar la lógica de orden
      if (this?.currentPricingPriced?.solveFor[0]?.value === SOLVER_FORS[4]) {
        return resultA > resultB ? -1 : resultA < resultB ? 1 : 0;
      } else {
        return resultA > resultB ? 1 : resultA < resultB ? -1 : 0;
      }
    }
  
    if (isValidA) return -1;
    if (isValidB) return 1;
  
    // 2. Luego los que aún están buscando (null o undefined)
    if (isSearchingA && !isSearchingB) return -1;
    if (!isSearchingA && isSearchingB) return 1;
  
    // 3. Luego los errores
    if (isErrorA && !isErrorB) return -1;
    if (!isErrorA && isErrorB) return 1;
  
    // 4. Finalmente los N/A
    if (isNAA && !isNAB) return 1;
    if (!isNAA && isNAB) return -1;
  
    return 0;
  };
  
  
  setLimit() {
    if (this.websocketTimeOut) {
      clearTimeout(this.websocketTimeOut);
    }
    this.websocketTimeOut = setTimeout(() => {
      this.presentToast(this.translate.instant('PRICER.TIMEOUT'));
      Object.keys(this.results).forEach(key => {
        this.insertResult(key, RESULT.ERROR, this.translate.instant('FORM.ERROR_RESULT'), this.translate.instant('FORM.ERROR_RESULT_MSG', { issuer: key }));
      });
      this.closeConnections();
      //this.closeAPIRequest = true;
    }, 600000);
  }
  closeConnections() {
    if (this.connection) {
      this.connection.emit('closeRoom', this.roomName);
      console.log('La sala siguiente sala se va cerrar', this.roomName);
      this.roomName = null;
    }
    if (this.timeoutSAN) {
      clearTimeout(this.timeoutSAN);
    }
    if (this.timeoutSG) {
      clearTimeout(this.timeoutSG);
    }
  }

  //** ALERTS AND TOASTS */
  async showDeleteAlert(und, index) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('SEARCH.DELETE_HEADER'),
      message: new IonicSafeString('<ion-item lines="none" fill="outline" style="--background: transparent!important;"><ion-avatar slot="start"><img src="./assets/img/logostemp/' + und.Website__c + '.png" onerror="src = \'./assets/img/logostemp/www.aisfg.com.png\'"/></ion-avatar><ion-label><h2>' + und.Name + '</h2><p>' + und.Name__c + '</p></ion-label></ion-item>'),
      buttons: [
        {
          text: this.translate.instant('SEARCH.DELETE'),
          role: 'destructive',
          handler: () => {
            this.selectedUnderlyings.splice(index, 1);
          }
        },
        {
          text: this.translate.instant('SEARCH.CLOSE'),
          handler: () => {
            //this.items.splice(i,1);
          }
        }
      ]
    });
    alert.present();
  }
  async presentSearchModal() {
    const modal = await this.modalCtrl.create({
      component: SearchModalPage,
      breakpoints: [0, 0.4, 0.9, 1],
      initialBreakpoint: 0.9,
      componentProps: {
        selectedIds: this.convertIds()
      },
    });
    modal.onDidDismiss()
      .then((underlying) => {
        if (underlying && underlying.data) {
          this.selectedUnderlyings.push(underlying.data);
        }
      });
    modal.present();
  }
  async presentToast(msg) {
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      buttons: [
        {
          text: this.translate.instant('SEARCH.CLOSE'),
          role: 'cancel'
        },
      ],
    });

    await toast.present();

    const { role } = await toast.onDidDismiss();
  }

  //** FORMAT REQUEST */
  convertIds(): string {
    if (this.selectedUnderlyings != null && this.selectedUnderlyings !== undefined && this.selectedUnderlyings.length > 0) {
      return '\'' + this.selectedUnderlyings.map(und => und.Id).join('\',\'') + '\'';
    }
    return null;
  }
  covertFormToJSON(pricingForm, underlyingList) {
    const json = pricingForm.getRawValue();
    this.compulsary.forEach(key => {
      json[key] = [{ 'label': json[key], 'selected': true, 'value': json[key] }];
    });
    Object.keys(this.visible).forEach(key => {
      console.log(key + ' ' + typeof json[key]);
      if (!this.visible[key]) {
        json[key] = null;
      } else if (typeof json[key] === 'string') {
        json[key] = [{ 'label': json[key], 'selected': true, 'value': json[key] }];
      } else if (key === 'firstCallInObs') {
        json[key] = [{ 'label': json[key] + '', 'selected': true, 'value': json[key] + '' }];
      }
    });
    json.underlyings = underlyingList.map(und => ({ 'Id': und.Id, 'Name': und.Name, 'Name__c': und.Name__c, 'RICCode__c': und.RICCode__c, 'Type__c': und.Type__c }));
    return json;
  }

  //** REPRICE  */
  updateLastSelectedUnderlyings(underlyings) {
    Preferences.get({ key: UNDERLYINGS_KEY }).then((unds) => {
      if (unds && unds.value) {
        const lastSearchs = JSON.parse(unds.value.toString());
        underlyings.forEach(reloadedUnd => {
          if (!lastSearchs.map(und => und.Id).includes(reloadedUnd?.Underlying__r?.Id)) {
            lastSearchs.push(reloadedUnd?.Underlying__r);
          }
        });
        Preferences.set({ key: UNDERLYINGS_KEY, value: JSON.stringify(lastSearchs) });
      } else {
        Preferences.set({ key: UNDERLYINGS_KEY, value: JSON.stringify(underlyings.map(und => und?.Underlying__r)) });
      }
    });


  }
  async showIssuerAlert(priceResult) {
    if (this.results[priceResult.key] != null) {
      console.log(priceResult);
      const resultMsg = priceResult?.value?.type === RESULT.ERROR ? this.translate.instant('HISTORIC.ERROR') : priceResult?.value?.type === RESULT.NOT_AVAILABLE ? this.translate.instant('HISTORIC.ERROR') : this.translate.instant('HISTORIC.QUOTE_ID');
      const alert = await this.alertCtrl.create({
        header: constants.issuers[priceResult.key],
        message: resultMsg + ': ' + priceResult?.value?.subtext,
        buttons: [
          {
            text: this.translate.instant('HISTORIC.COPY'),
            role: 'cancel',
            handler: () => {
              // eslint-disable-next-line id-blacklist
              Clipboard.write({ string: priceResult?.value?.subtext });
              this.presentToast(this.translate.instant('HISTORIC.COPIED'));
              //this.selectedUnderlyings.splice(index, 1);
            }
          },
          {
            text: this.translate.instant('HISTORIC.CLOSE'),
            handler: () => {
              //this.items.splice(i,1);
            }
          }
        ]
      });
      alert.present();
    }
  }
  setPricing(pricing) {
    this.selectedUnderlyings = [];
    if (this.segments) {
      this.segments.value = '0';
    }
    if (this.slides) {
      this.slides.nativeElement.swiper.slideTo(0);
      this.content.scrollToTop(400);
      this.slides.nativeElement.swiper.allowTouchMove = false;
    }
    this.seeResults = false;

    this.updateLastSelectedUnderlyings(pricing?.PricerUnderlyings__r?.records);
    pricing?.PricerUnderlyings__r?.records.forEach(und => {
      const undWithId = und.Underlying__r;
      undWithId.Id = und.Underlying__c;
      this.selectedUnderlyings.push(undWithId);
    });

    this.pricing.controls.productRecordType.setValue(pricing.ProductRecordType__c);
    this.onTypeChangeInternal(pricing.ProductRecordType__c);//shot onchange in selects
    this.pricing.controls.productSubtype.setValue(pricing.ProductSubtype__c);
    this.onSubTypeChangeInternal(pricing.ProductSubtype__c);
    this.pricing.controls.solveFor.setValue(pricing.Solvefor__c);
    this.onSolveForChangeIntenal(pricing.Solvefor__c);
    this.pricing.controls.notionalAmount.setValue(pricing.NotionalAmmount__c);
    this.pricing.controls.isoCurrency.setValue(pricing.CurrencyIsoCode);
    this.pricing.controls.maturity.setValue(pricing.Maturity__c);
    this.onMaturityChangeInternal(pricing.Maturity__c);
    this.pricing.controls.offerPrice.setValue(pricing.OfferPrice__c);
    this.pricing.controls.putStrike.setValue(pricing.PutStrike__c);
    this.onPutStrikeChangeInternal(pricing.PutStrike__c);
    this.pricing.controls.barrierType.setValue(pricing.BarrierType__c);
    this.pricing.controls.barrierLevel.setValue(pricing.BarrierLevel__c);
    this.pricing.controls.onestar.setValue(pricing.OneStar__c);
    this.pricing.controls.onestarlevel.setValue(pricing.OneStarLevel__c);
    this.onOneStarChangeInternal(pricing.OneStar__c);
    this.pricing.controls.capitalprotection.setValue(pricing.CapitalProtection__c);
    this.pricing.controls.couponpa.setValue(pricing.Couponpa__c);
    this.pricing.controls.couponTrigger.setValue(pricing.CouponTrigger__c);
    this.pricing.controls.couponType.setValue(pricing.CouponType__c);
    this.pricing.controls.observationFrequency.setValue(pricing.ObservationFrequency__c);
    this.onObservationFrequencyChangeInternal(pricing.ObservationFrequency__c);
    this.pricing.controls.firstCallInObs.setValue(pricing.FirstCallInObservation__c);
    this.pricing.controls.autocallTrigger.setValue(pricing.AutocallTrigger__c);
    this.pricing.controls.autocallStepDown.setValue(pricing.AutocallStepDown__c ? pricing.AutocallStepDown__c : 0);
    this.pricing.controls.callStrike.setValue(pricing.CallStrike__c);
    this.pricing.controls.participation.setValue(pricing.Participation__c);
    this.pricing.controls.cap.setValue(pricing.Cap__c);
    this.pricing.controls.capLevel.setValue(pricing.CapLevel__c);
    this.onCapChangeInternal(pricing.Cap__c);

    this.presentToast(this.translate.instant('FORM.REPRICE', { pr: pricing.Name }));
  }
  unsorted() { }
  async selectPricesToShare() {
    
    const orderedResults = Object.keys(this.results)
    .map(key => ({ key, value: this.results[key] }))
    .sort(this.ordered)
    .reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    }, {});
    const modal = await this.modalCtrl.create({
      component: PriceSelectorModalComponent,
      componentProps: {
        results: orderedResults,
        issuers:this.issuers
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        const selectedResults = dataReturned.data;
        if (Object.keys(selectedResults).length > 0) {
          this.promptShareFormat(selectedResults);
        }
      }
    });
  
    return await modal.present();
  }
  
  async promptShareFormat(selectedResults) {
    const selectedResults2 = {
      'Product': 'Phoenix Autocall',
      'Underlyings': 'Apple',
      'Tickers': 'AAPL UW',
      'Currency': 'USD',
      'Maturity': '24 months',
      'Put Strike': 'NULL',
      'Frequency': 'Annually',
      'Autocall Trigger': '100%',
      'Barrier': '70% (European)',
      'Coupon Trigger': '70%',
      'Coupon p.a.': 'NULL',
      'Memory Effect': 'True',
      'Capital Protection': 'NULL',
      'Call Strike': 'NULL',
      'Participation': 'NULL',
      'Cap': 'NULL'
    };
  
    console.log('Llamando a sendTableData con:', selectedResults2);
  
    try {
      // Llamada a la API que devuelve el blob
      const imageBlob = await this.sendTableData(selectedResults2);
  
      this.alertCtrl.create({
        header: 'Select format to share',
        buttons: [
          {
            text: 'Share as Text',
            handler: () => {
              this.shareSelectedPrices(selectedResults2, 'text');
            }
          },
          {
            text: 'Share as Image',
            handler: () => {
              this.shareSelectedPrices(imageBlob, 'image');  // Pasar el blob para compartir como imagen
            }
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      }).then(alert => alert.present());
    } catch (error) {
      console.log('Error al generar la imagen', error);
    }
  }
  
  
  
  async sendTableData(selectedResults: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.dataService.sendTableData(selectedResults).subscribe({
        next: (res) => {
          console.log('respuestaaaaaaa ', res);
          // Asegúrate de que 'res' contenga un blob
          resolve(res.response); // Esto asume que 'res' es el blob, si no lo es, ajusta según la respuesta de la API
        },
        error: (err) => {
          console.log('Error loading most used underlyings', err);
          reject(err);
        }
      });
    });
  }
  
  
  
  
  
  
  shareSelectedPrices(selectedResults, format: 'text' | 'image' | 'excel') {
    console.log('Format ', format);
  
    switch (format) {
      case 'text':
        this.shareAsText(selectedResults);
        break;
      case 'image':
        this.shareAsImage(selectedResults); // Aquí ya debería ser un blob cuando es 'image'
        break;
      default:
        console.log('Unknown format');
    }
  }
  

async shareAsText(selectedResults) {
  //const textToShare = 'Here are the selected prices:\n' + selectedResults.join('\n');

  await Share.share({
    title: 'Share Prices',
    text: 'HOLA',
    dialogTitle: 'Share Prices',
  });
}

async shareAsImage(base64data) {
  const fileName = `shared-image-${new Date().getTime()}.png`;

  try {
    const base64Content = base64data.startsWith('data:image/')
      ? base64data
      : `data:image/png;base64,${base64data}`;

    // Descargar la imagen
    await this.downloadImageBase64(base64Content, fileName);

    console.log('Image downloaded successfully');

  } catch (error) {
    console.log('Error downloading the file:', error);
  }
}

// Función para descargar la imagen usando directamente Base64
downloadImageBase64(base64Data: string, fileName: string) {
  const link = document.createElement('a');
  link.href = base64Data; // Usa el Base64 directamente con prefijo
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Elimina el enlace una vez usado
}



}
